import { Dispatch } from "redux";
import {
  FETCH_SETTINGS,
  FETCH_SETTINGS_FAILURE,
  FETCH_SETTINGS_SUCCESS,
  SAVE_USER_SETTING,
  SAVE_USER_SETTING_FAILURE,
  SAVE_USER_SETTING_SUCCESS,
  UPDATE_USER_SETTING,
} from "../constants";
import { SettingsActionTypes } from "../types/settingsActionTypes";
import { settingsService } from "../../services/settingsService";
import { SettingsState } from "../../model/admin/settingsState";
import { DailyMetricCardVisibility } from "../../model/config/DailyMetricCardVisibility";
import { DailyParameterCardVisibility } from "../../model/config/DailyParameterCardVisibility";
import { PatientTestCardVisibility } from "../../model/config/PatientTestCardVisibility";
import { ThunkAppDispatch } from "../configureStore";

export function fetchSettings(): SettingsActionTypes {
  return {
    type: FETCH_SETTINGS,
  };
}

export function fetchSettingsSuccess(data: any) {
  return {
    type: FETCH_SETTINGS_SUCCESS,
    payload: data,
  };
}

export function fetchSettingsError(error: string): SettingsActionTypes {
  return {
    type: FETCH_SETTINGS_FAILURE,
    payload: error,
  };
}

interface Setting {
  id: number;
  name: string;
  value: string;
}

interface Setting {
  id: number;
  name: string;
  value: string;
}

interface Setting {
  id: number;
  name: string;
  value: string;
}

export function getSettings(accountGuid: string) {
  return function action(dispatch: Dispatch) {
    dispatch(fetchSettings());
    return settingsService
      .getEnvironment()
      .then((environmentResponse: Setting[]) => {
        const environmentSettings = environmentResponse || [];
        settingsService.getUser(accountGuid).then((userResponse: Setting[]) => {
          const userSettings = userResponse || [];

          console.log(
            "the settings before processing",
            environmentSettings,
            userSettings
          );

          // Filter relevant settings
          const relevantSettings = [
            "DailyMetricsVisibility",
            "DailyParametersVisibility",
            "PatientTestVisibility",
          ];

          // Create a map of user settings for quick lookup
          const userSettingsMap = new Map(
            userSettings
              .filter((setting: Setting) =>
                relevantSettings.includes(setting.name)
              )
              .map((setting: Setting) => [setting.name, setting])
          );

          // Merge environment settings into user settings
          const mergedSettings = environmentSettings
            .filter((envSetting: Setting) =>
              relevantSettings.includes(envSetting.name)
            )
            .map((envSetting: Setting) => {
              const userSetting = userSettingsMap.get(envSetting.name);
              if (userSetting) {
                const envValue = JSON.parse(envSetting.value);
                const userValue = JSON.parse(userSetting.value);
                const mergedValue = {
                  ...envValue,
                  ...userValue,
                };
                console.log(
                  `Merging ${envSetting.name}:`,
                  envValue,
                  userValue,
                  mergedValue
                );
                return {
                  ...envSetting,
                  value: JSON.stringify(mergedValue),
                };
              }
              return envSetting;
            });

          // Add any user settings that are not in the environment settings
          userSettings
            .filter((userSetting: Setting) =>
              relevantSettings.includes(userSetting.name)
            )
            .forEach((userSetting: Setting) => {
              if (
                !mergedSettings.some(
                  (setting) => setting.name === userSetting.name
                )
              ) {
                mergedSettings.push(userSetting);
              }
            });

          console.log(
            "the settings before dispatch",
            environmentSettings,
            mergedSettings
          );

          dispatch(
            fetchSettingsSuccess({
              environment: environmentSettings,
              user: mergedSettings,
            })
          );
        });
      });
    // error => dispatch(someerror('woody', buzz, error))
  };
}

export const updateUserSetting = (
  category: keyof SettingsState,
  key: string,
  value: boolean
) => {
  return (dispatch: ThunkAppDispatch) => {
    return new Promise<void>((resolve, reject) => {
      try {
        dispatch({
          type: UPDATE_USER_SETTING,
          payload: { category, key, value },
        });
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };
};

export function saveUserSetting(): SettingsActionTypes {
  return {
    type: SAVE_USER_SETTING,
  };
}

export function saveUserSettingsSuccess() {
  return {
    type: SAVE_USER_SETTING_SUCCESS,
  };
}

export function saveUserSettingsError(error: string): SettingsActionTypes {
  return {
    type: SAVE_USER_SETTING_FAILURE,
    payload: error,
  };
}

type VisibilityTypes =
  | DailyMetricCardVisibility
  | DailyParameterCardVisibility
  | PatientTestCardVisibility;

export function saveUserSettings(
  accountId: string,
  name: keyof SettingsState,
  value: VisibilityTypes
) {
  return function action(dispatch: Dispatch) {
    dispatch(saveUserSetting());
    const stringValue = JSON.stringify(value);
    return settingsService.userSave(accountId, name, stringValue).then(
      (response: any) => dispatch(saveUserSettingsSuccess()),
      (error: string) => dispatch(saveUserSettingsError(error))
    );
  };
}
