import { fetch } from "../../helpers/api/api";
import { DateGroupType } from "../../model/active/dateGroupType";

const baseUrl = `${process.env.REACT_APP_API_URL}/passive`;
const baseUrlMetrics = `${process.env.REACT_APP_API_URL}/dailymetrics`;

export const passiveParametersService = {
  getDailyParametersDatesByRange,
  getDailyParametersByDate,
  getDailyParameterDatesByRange,
  getAverageWalkingSpeed,
};

function getDailyParametersDatesByRange(
  patientId: string,
  startDate: string,
  endDate: string
) {
  return fetch(`${baseUrl}/dates/${patientId}/${startDate}/${endDate}`).then(
    (response: any) => {
      return response.body;
    }
  );
}

//This needs /dailymetrics because the function exists on a different BE controller - may be refactored at some point
function getDailyParametersByDate(accountGuid: string, date: Date) {
  const requestOptions = {
    method: "GET",
  };
  return fetch(
    `${baseUrlMetrics}/date?accountGuid=${accountGuid}&date=${date}`,
    requestOptions
  ).then((response: any) => {
    return response.body;
  });
}

function getDailyParameterDatesByRange(
  accountGuid: string,
  dateStart: string,
  dateEnd: string
) {
  const requestOptions = {
    method: "GET",
  };

  //TODO - Hardcoded to AverageWalkingSpeed for now - Kris

  return fetch(
    `${baseUrl}/dates/${accountGuid}/${dateStart}/${dateEnd}?type=AverageWalkingSpeed`,
    requestOptions
  ).then((response: any) => {
    return response.body;
  });
}

function getAverageWalkingSpeed(
  accountGuid: string,
  dateStart: Date,
  dateEnd: Date,
  dateType: DateGroupType
) {
  const requestOptions = {
    method: "GET",
  };

  //TODO: There is a bit of confusion about how the system is handling DateTime - we are converting to ISO string here becasue of a clash using a converted DateTime using the theme value
  const start = new Date(dateStart).toISOString();
  const end = new Date(dateEnd).toISOString();

  return fetch(
    `${baseUrlMetrics}/by-date-range/?accountGuid=${accountGuid}&type=AverageWalkingSpeed&dateStart=${start}&dateEnd=${end}&dateType=${dateType}`,
    requestOptions
  ).then((response: any) => {
    return response.body;
  });
}
