import merge from "lodash/merge";
import { store } from "../../redux/configureStore";
import {
  refreshTokenBegin,
  refreshTokenSuccess,
  refreshTokenError,
} from "../../redux/actions/accountActions";
import { handleResponse } from "../handleResponse";

import { configureRefreshFetch, fetchJSON } from "../../modules/refreshFetch";

const baseUrl = `${process.env.REACT_APP_API_URL}`;

const fetchJSONWithToken = (url, options = {}) => {
  const token = retrieveToken();

  let optionsWithToken = options;
  if (token != null) {
    optionsWithToken = merge({}, options, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  return fetchJSON(url, optionsWithToken);
};

//this is here for now as it's being used as part of the setup for token refresh - at some point it might be nice to have this in account service
const login = (email, password, twoFactorAuthenticationCode) => {
  return fetchJSON(`${baseUrl}/accounts/authenticate`, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({
      email,
      password,
      twoFactorAuthenticationCode,
    }),
  }).then((response) => {
    const handle = handleResponse(response);
    handle.then((data) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      const state = store.getState();
      data.rememberMe = state.account.account.rememberMe;
      data.isTwoFactorAuthenticationPassed =
        state.account.account.isTwoFactorAuthenticationPassed;
      localStorage.setItem("account", JSON.stringify(data));
    });
    return handle;
  });
};

const shouldRefreshToken = (error) => {
  return error.response.status === 401; //&& error.body.message === "Token has expired";
};

const refreshToken = () => {
  store.dispatch(refreshTokenBegin());
  return fetchJSON(`${baseUrl}/accounts/refresh-token`, {
    method: "POST",
    credentials: "include",
  })
    .then((response) => {
      //TODO: im not totally conviced that keeping the token in two locations is a good idea - perhaps revisit this and come up with something better
      store.dispatch(refreshTokenSuccess(response.body));
      const state = store.getState();
      localStorage.setItem("account", JSON.stringify(state.account.account));
    })
    .catch((error) => {
      store.dispatch(refreshTokenError(error));
      if (
        error.response.status === 400 &&
        error.body.message === "Invalid token"
      ) {
        //an invalid token was used, so kick user out
        window.location.href = "/";
        localStorage.removeItem("account");
      }
    });
};

const fetch = configureRefreshFetch({
  fetch: fetchJSONWithToken,
  shouldRefreshToken,
  refreshToken,
});

const retrieveToken = () => {
  const state = store.getState();
  return state.account.account.jwtToken;
};

export { fetch, login };
