import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import InviteUserComponent from "./InviteUserComponent";
import ModifyAccountComponent from "./ModifyAccountComponent";
import ManageInvitesComponent from "./ManageInvitesComponent";

const useStyles = makeStyles((theme: any) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    gap: "20px",
    overflow: "auto",
    "&:last-child": {
      //slight hack using a Border here - needed to play nice with Overflow
      borderBottom: "20px solid transparent",
    },
  },
}));

export const AdminPanelPage: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.content}>
      <Typography variant="h4" component="h2">
        Admin
      </Typography>
      <InviteUserComponent />
      <ModifyAccountComponent />
      <ManageInvitesComponent />
    </div>
  );
};

export default AdminPanelPage;
