import {
  CHANGE_ACCOUNT_ROLE,
  FETCH_ACCOUNTS_SUCCESS,
  CLEAR_ACCOUNTS,
  FETCH_INVITES_SUCCESS,
  TOGGLE_ACCOUNT_2FA_ENABLED_SUCCESS,
} from "../constants";
import { AdminState } from "../../model/admin/adminState";
import { AdminActionTypes } from "../types/adminActionTypes";
import { initialState } from "../system/SystemState";

export default function adminReducer(
  state = initialState.admin,
  action: AdminActionTypes
): AdminState {
  switch (action.type) {
    case FETCH_ACCOUNTS_SUCCESS:
      return {
        ...state,
        accounts: action.payload,
      };
    case CHANGE_ACCOUNT_ROLE:
      return {
        ...state,
        accounts: state.accounts.map((el) => {
          if (el.accountGuid === action.payload.accountId) {
            return {
              ...el,
              role: action.payload.role,
            };
          }
          return el;
        }),
      };
    case CLEAR_ACCOUNTS:
      return {
        ...state,
        accounts: [],
      };
    case FETCH_INVITES_SUCCESS:
      return {
        ...state,
        invitationCodes: action.payload,
      };
    case TOGGLE_ACCOUNT_2FA_ENABLED_SUCCESS:
      return {
        ...state,
        accounts: state.accounts.map((el) => {
          if (el.accountGuid === action.payload.accountGuid) {
            return {
              ...el,
              isTwoFactorAuthenticationEnabled: action.payload.isEnabled,
            };
          }
          return el;
        }),
      };
    default:
      return state;
  }
}
