import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppThunkDispatch } from "../../../redux/configureStore";
import { getPatients } from "../../../redux/actions/patientActions";
import { Typography, IconButton, Box, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RefreshIcon from "@mui/icons-material/Refresh";
import { makeStyles } from "@mui/styles";
import { selectAccount } from "../../../redux/selectors/accountSelectors";

interface Props {
  setIsLoading: (params: any) => any;
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    marginRight: 30,
  },
  personActions: {
    display: "flex",
  },
}));

export const PatientsHeader: React.FC<Props> = ({ setIsLoading }) => {
  const classes = useStyles();
  const account = useSelector(selectAccount);
  const dispatch = useAppThunkDispatch();
  let navigate = useNavigate();

  const handleRefreshPatients = () => {
    if (account.accountGuid) {
      setIsLoading(true);
      dispatch(getPatients(account.accountGuid, true)).then((response: any) => {
        setIsLoading(false);
      });
    }
  };

  const handleAddPatient = () => {
    navigate("/dashboard/patients/add");
  };
  return (
    <div className={classes.root}>
      <Typography variant="h4" component="h4">
        Your Patients
      </Typography>

      <Box className={classes.personActions}>
        <Tooltip title="Refresh Patients">
          <IconButton
            aria-label="refresh"
            size="large"
            onClick={handleRefreshPatients}
          >
            <RefreshIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
        <Tooltip title="">
          <IconButton
            aria-label="delete"
            size="large"
            onClick={handleAddPatient}
          >
            <AddIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </Box>
    </div>
  );
};

export default PatientsHeader;
