export const CHECK_CREDENTIALS = "CHECK_CREDENTIALS";
export const CHECK_CREDENTIALS_SUCCESS = "CHECK_CREDENTIALS_SUCCESS";
export const CHECK_CREDENTIALS_FAILURE = "CHECK_CREDENTIALS_FAILURE";

export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";

export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const LOGOUT_USER_FAILURE = "LOGOUT_USER_FAILURE";

export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAILURE = "REFRESH_TOKEN_FAILURE";

export const FETCH_PATIENTS = "FETCH_PATIENTS";
export const FETCH_PATIENTS_SUCCESS = "FETCH_PATIENTS_SUCCESS";
export const FETCH_PATIENTS_FAILURE = "FETCH_PATIENTS_FAILURE";

export const FETCH_PATIENT_TESTS = "FETCH_PATIENT_TESTS";
export const FETCH_PATIENT_TESTS_SUCCESS = "FETCH_PATIENT_TESTS_SUCCESS";
export const FETCH_PATIENT_TESTS_FAILURE = "FETCH_PATIENT_TESTS_FAILURE";

export const FETCH_PATIENT_TEST_DETAIL = "FETCH_PATIENT_TEST_DETAIL";
export const FETCH_PATIENT_TEST_DETAIL_SUCCESS =
  "FETCH_PATIENT_TEST_DETAIL_SUCCESS";
export const FETCH_PATIENT_TEST_DETAIL_FAILURE =
  "FETCH_PATIENT_TEST_DETAIL_FAILURE";

export const FETCH_PATIENT_TEST_TABLE_DATA = "FETCH_PATIENT_TEST_TABLE_DATA";
export const FETCH_PATIENT_TEST_TABLE_DATA_SUCCESS =
  "FETCH_PATIENT_TEST_TABLE_DATA_SUCCESS";
export const FETCH_PATIENT_TEST_TABLE_DATA_FAILURE =
  "FETCH_PATIENT_TEST_TABLE_DATA_FAILURE";

export const CLEAR_SELECTED_PATIENT_TEST_DETAIL =
  "CLEAR_SELECTED_PATIENT_TEST_DETAIL";

export const FETCH_DAILY_METRIC_DATES = "FETCH_DAILY_METRIC_DATES";
export const FETCH_DAILY_METRIC_DATES_SUCCESS =
  "FETCH_DAILY_METRIC_DATES_SUCCESS";
export const FETCH_DAILY_METRIC_DATES_FAILURE =
  "FETCH_DAILY_METRIC_DATES_FAILURE";

export const FETCH_DAILY_METRICS = "FETCH_DAILY_METRICS";
export const FETCH_DAILY_METRICS_SUCCESS = "FETCH_DAILY_METRICS_SUCCESS";
export const FETCH_DAILY_METRICS_FAILURE = "FETCH_DAILY_METRICS_FAILURE";
export const SELECT_DAILY_METRIC_DATE = "SELECT_DAILY_METRIC_DATE";

export const CLEAR_SELECTED_DAILY_METRICS = "CLEAR_SELECTED_DAILY_METRICS";

export const FETCH_ALL_ALERT_DETAIL = "FETCH_ALL_ALERT_DETAIL";
export const FETCH_ALL_ALERT_DETAIL_SUCCESS = "FETCH_ALL_ALERT_DETAIL_SUCCESS";
export const FETCH_ALL_ALERT_DETAIL_FAILURE = "FETCH_ALL_ALERT_DETAIL_FAILURE";

export const CLEAR_ALL_ALERT_DETAIL = "CLEAR_ALL_ALERT_DETAIL";

export const FETCH_ACCOUNTS = "FETCH_ACCOUNTS";
export const FETCH_ACCOUNTS_SUCCESS = "FETCH_ACCOUNTS_SUCCESS";
export const FETCH_ACCOUNTS_FAILURE = "FETCH_ACCOUNTS_FAILURE";

export const FETCH_INVITES = "FETCH_INVITES";
export const FETCH_INVITES_SUCCESS = "FETCH_INVITES_SUCCESS";
export const FETCH_INVITES_FAILURE = "FETCH_INVITES_FAILURE";

export const CHANGE_ACCOUNT_ROLE = "CHANGE_ACCOUNT_ROLE";
export const CLEAR_ACCOUNTS = "CLEAR_ACCOUNTS";

export const FETCH_ACTIVE_OVERVIEW = "FETCH_ACTIVE_OVERVIEW";
export const FETCH_ACTIVE_OVERVIEW_SUCCESS = "FETCH_ACTIVE_OVERVIEW_SUCCESS";
export const FETCH_ACTIVE_OVERVIEW_FAILURE = "FETCH_ACTIVE_OVERVIEW_FAILURE";
export const SELECT_ACTIVE_TEST_DATE = "SELECT_ACTIVE_TEST_DATE";

export const ENABLE_2FA = "ENABLE_2FA";

export const TOGGLE_ACCOUNT_2FA_ENABLED = "TOGGLE_ACCOUNT_2FA_ENABLED";
export const TOGGLE_ACCOUNT_2FA_ENABLED_SUCCESS =
  "TOGGLE_ACCOUNT_2FA_ENABLED_SUCCESS";
export const TOGGLE_ACCOUNT_2FA_ENABLED_FAILURE =
  "TOGGLE_ACCOUNT_2FA_ENABLED_AILURE";

//dailyParameters
export const FETCH_DAILY_PARAMETER_DATES = "FETCH_DAILY_PARAMETER_DATES";
export const FETCH_DAILY_PARAMETER_DATES_SUCCESS =
  "FETCH_DAILY_PARAMETER_DATES_SUCCESS";
export const FETCH_DAILY_PARAMETER_DATES_FAILURE =
  "FETCH_DAILY_PARAMETER_DATES_FAILURE";

export const FETCH_DAILY_PARAMETER_DATA = "FETCH_DAILY_PARAMETER_DATA";
export const FETCH_DAILY_PARAMETER_DATA_SUCCESS =
  "FETCH_DAILY_PARAMETER_DATA_SUCCESS";
export const FETCH_DAILY_PARAMETER_DATA_FAILURE =
  "FETCH_DAILY_PARAMETER_DATA_FAILURE";
export const CLEAR_SELECTED_DAILY_PARAMETER = "CLEAR_SELECTED_DAILY_PARAMETER";

export const SET_REMEMBER_ME = "SET_REMEMBER_ME";

export const SET_2FA_STATUS = "SET_2FA_STATUS";

export const FETCH_AVERAGE_WALKING_SPEED = "FETCH_AVERAGE_WALKING_SPEED";

//activeTests
export const FETCH_ACTIVE_TEST_DATES = "FETCH_ACTIVE_TEST_DATES";
export const FETCH_ACTIVE_TEST_DATES_SUCCESS =
  "FETCH_ACTIVE_TEST_DATES_SUCCESS";
export const FETCH_ACTIVE_TEST_DATES_FAILURE =
  "FETCH_ACTIVE_TEST_DATES_FAILURE";

export const FETCH_ACTIVE_TEST_DATA = "FETCH_ACTIVE_TEST_DATA";
export const FETCH_ACTIVE_TEST_DATA_SUCCESS = "FETCH_ACTIVE_TEST_DATA_SUCCESS";
export const FETCH_ACTIVE_TEST_DATA_FAILURE = "FETCH_ACTIVE_TEST_DATA_FAILURE";
export const CLEAR_ACTIVE_TEST_DATES = "CLEAR_ACTIVE_TEST_DATES";
export const CLEAR_ACTIVE_TEST_DATA = "CLEAR_ACTIVE_TEST_DATA";
export const SET_SELECTED_TEST_DATE = "SET_SELECTED_TEST_DATE";

//settings
export const FETCH_SETTINGS = "FETCH_SETTINGS";
export const FETCH_SETTINGS_SUCCESS = "FETCH_SETTINGS_SUCCESS";
export const FETCH_SETTINGS_FAILURE = "FETCH_SETTINGS_FAILURE";
export const UPDATE_USER_SETTING = "UPDATE_USER_SETTING";
export const SAVE_USER_SETTING = "SAVE_USER_SETTING";
export const SAVE_USER_SETTING_SUCCESS = "SAVE_USER_SETTING_SUCCESS";
export const SAVE_USER_SETTING_FAILURE = "SAVE_USER_SETTING_FAILURE";
