import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Box, Link } from "@mui/material";
import Typography from "@mui/material/Typography";
import {
  selectAccount,
  selectAccountError,
} from "../../../redux/selectors/accountSelectors";
import { useSelector } from "react-redux";
import { AuthCodeInput } from "../../documented/AuthCodeInput";
import { LoadingButton } from "@mui/lab";
import { AccountTypeId } from "../../../model/account/accountTypeId";
import { useAppThunkDispatch } from "../../../redux/configureStore";
import {
  loginUser,
  loginUserError,
  loginUserSuccess,
  set2faStatus,
} from "../../../redux/actions/accountActions";
import { login } from "../../../helpers/api/api";

const useStyles = makeStyles((theme) => ({
  input: {
    width: "45px",
    height: "45px",
    padding: "0",
    fontSize: "24px",
    textAlign: "center",
    marginRight: "12px",
    textTransform: "uppercase",
    color: "#494949",
    border: "1px solid #d6d6d6",
    borderRadius: "4px",
    background: "#fff",
    backgroundClip: "padding-box",
    "&:hover": {
      appearance: "none",
      outline: "0",
      boxShadow: "0 0 0 3px rgb(131 192 253 / 50%)",
    },
  },
  inputError: {
    width: "45px",
    height: "45px",
    padding: "0",
    fontSize: "24px",
    textAlign: "center",
    marginRight: "12px",
    textTransform: "uppercase",
    color: "#494949",
    border: "1px solid red",
    borderRadius: "4px",
    background: "#fff",
    backgroundClip: "padding-box",
    "&:hover": {
      appearance: "none",
      outline: "0",
      boxShadow: "0 0 0 3px rgb(131 192 253 / 50%)",
    },
  },
  container: {
    padding: "16px",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SendTwoFactorAuthComponent: React.VoidFunctionComponent = () => {
  const classes = useStyles();
  const account = useSelector(selectAccount);
  const error = useSelector(selectAccountError);
  let navigate = useNavigate();
  const dispatch = useAppThunkDispatch();

  const innerRef = useRef<HTMLButtonElement>();
  const [isLoading, setIsLoading] = useState(false);
  const [isInputComplete, setIsInputComplete] = useState(false);
  const [inputCode, setInputCode] = useState("");
  const inputLength = 6;

  /*   useEffect(() => {
    return () => {
      // This cleanup function is here to fix a potential memory leak
      setIsLoading(false);
      setIsInputComplete(false);
      setInputCode("");
    };
  }, []); */

  useEffect(() => {
    if (inputCode.length >= inputLength) {
      setIsInputComplete(true);
      innerRef?.current?.focus();
    }
  }, [inputCode]);

  function handleChange(e: string) {
    setInputCode(e);
    if (e && /^[1-9]\d{5}$/.test(e.trim())) {
      setIsInputComplete(true);
    } else {
      setIsInputComplete(false);
    }
  }

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    dispatch(loginUser());
    login(account.email, account.password, inputCode)
      .then((response: any) => {
        dispatch(set2faStatus(true));
        response.isTwoFactorAuthenticationPassed = true;
        dispatch(loginUserSuccess(response));
        //TODO: This is a slight hack as the dispatch set2fastatus isnt happening in the right order
        localStorage.setItem("account", JSON.stringify(response));

        if (
          response.accountType.accountTypeId ===
          AccountTypeId.HealthcareProfessional
        ) {
          navigate("/dashboard/patients");
        } else {
          navigate(`/dashboard/patients/${response.accountGuid}`);
        }
      })
      .catch((error: any) => {
        dispatch(loginUserError(error.body.message));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const LoadingButtonRef = forwardRef((props: any, ref: any) => {
    return (
      <LoadingButton
        ref={ref}
        loading={isLoading}
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={!isInputComplete}
        onClick={onSubmit}
      >
        Send
      </LoadingButton>
    );
  });

  return (
    <>
      <Typography variant="h4">
        <strong>Two Factor Authentication</strong>
      </Typography>
      <Box
        sx={{
          mt: 2,
          mb: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            mb: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography>
            Please enter the verification code displayed in your authenticator
            app to continue
          </Typography>
          <AuthCodeInput
            autoFocus
            isNumberInput
            length={inputLength}
            className={classes.container}
            inputClassName={!error ? classes.input : classes.inputError}
            onChangeOTP={handleChange}
            errorMessage={error ? error! : ""}
          />

          <LoadingButtonRef ref={innerRef} />
        </Box>

        <Link href="/" variant="body2" sx={{ mt: 1 }}>
          click here to return home
        </Link>
      </Box>
    </>
  );
};

export default SendTwoFactorAuthComponent;
