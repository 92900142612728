import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { Box, TextField } from "@mui/material";
import accountService from "../../services/AccountService";
import { LoadingButton } from "@mui/lab";
import { FORM_STATUS } from "../../model/formStatus";

const useStyles = makeStyles((theme: any) => ({
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  username: {
    float: "left",
  },
  password: {
    float: "right",
  },
  textField: {
    "& .Mui-focused legend": {
      paddingRight: 20,
    },
  },
  passwordField: {
    "& .Mui-focused legend": {
      paddingRight: 10,
    },
  },
}));

const defaultState = {
  email: "",
};

const ForgotPasswordComponent: React.VoidFunctionComponent = () => {
  const classes = useStyles();

  const [formData, setFormData] = useState(defaultState);
  const [status, setStatus] = useState(FORM_STATUS.IDLE);
  const [touched, setTouched] = useState(defaultState);

  const [statusMessage, setStatusMessage] = useState(
    "Please wait while we verify your email address"
  );
  const [requestSuccess, setRequestsuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function handleChange(e: any) {
    e.persist();
    setFormData((curFormData) => {
      return {
        ...curFormData,
        [e.target.id]: e.target.value,
      };
    });
  }

  function handleBlur(e: any) {
    e.persist();
    setTouched((cur: any) => {
      return { ...cur, [e.target.id]: true };
    });
  }

  function getErrors(formData: any) {
    const result = {
      email: "",
    };
    if (status === FORM_STATUS.COMPLETED) return result;

    if (!formData.email) {
      result.email = "Please enter your email address.";
    } else if (!/^.+@.+$/i.test(formData.email)) {
      result.email = "Your email address is Invalid.";
    }

    return result;
  }

  //Derived state
  const errors = getErrors(formData);
  const isValid = Object.values(errors).every((x) => x === null || x === "");

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setStatus(FORM_STATUS.SUBMITTING);
    if (isValid) {
      setIsLoading(true);
      try {
        accountService.forgotPassword(formData.email).then((response: any) => {
          setStatus(FORM_STATUS.COMPLETED);
          setFormData(defaultState);
          setRequestsuccess(true);
          setStatusMessage(response.body.message);
        });
      } catch (error: any) {
        setStatusMessage(error.body.message);
        setRequestsuccess(false);
      } finally {
        setIsLoading(false);
      }
    } else {
      setStatus(FORM_STATUS.SUBMITTED);
    }
  };

  return (
    <>
      <Typography variant="h4">
        <strong>Forgot Password</strong>
      </Typography>

      <Box component="form" noValidate sx={{ mt: 2 }} onSubmit={onSubmit}>
        <TextField
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          value={formData.email}
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={status === FORM_STATUS.COMPLETED ? true : false}
          helperText={
            (touched.email || status === FORM_STATUS.SUBMITTED) && errors.email
          }
          error={
            (touched.email !== "" || status === FORM_STATUS.SUBMITTED) &&
            errors.email !== ""
          }
        />
        <LoadingButton
          type="submit"
          loading={isLoading}
          variant="contained"
          color="primary"
          className={classes.submit}
          sx={{ mt: 3 }}
          disabled={status === FORM_STATUS.COMPLETED ? true : false}
        >
          Submit
        </LoadingButton>
      </Box>
      <Box
        sx={{
          mt: 2,
          mb: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {requestSuccess ? (
          <>
            <Typography variant="body1" sx={{ mt: 1 }}>
              {statusMessage}
            </Typography>
            <Link href="/" variant="body2" sx={{ mt: 1 }}>
              click here to return home
            </Link>
          </>
        ) : (
          <Link href="/" variant="body2" sx={{ mt: 1 }}>
            click here to return home
          </Link>
        )}
      </Box>
    </>
  );
};

export default ForgotPasswordComponent;
