import { fetch } from "../../helpers/api/api";
import { fetchJSON } from "../../modules/refreshFetch";
import { VerifyEmailRequest } from "../../model/account/verifyEmailRequest";
import { ForgotPasswordRequest } from "../../model/account/forgotPasswordRequest";
import { ResetPasswordRequest } from "../../model/account/resetPasswordRequest";
import { ValidateResetTokenRequest } from "../../model/account/validateResetTokenRequest";
import { SwitchAdminRequest } from "../../model/admin/switchAdminRequest";
import { TwoFactorSetupCodeResponse } from "../../model/account/twoFactorAuthentication/twoFactorSetupCodeResponse";
import { AuthenticateRequest } from "../../model/account/authenticateRequest";

const baseUrl = `${process.env.REACT_APP_API_URL}/accounts`;

export const accountService = {
  checkCredentials,
  verifyEmail,
  forgotPassword,
  validateResetToken,
  resetPassword,
  revokeToken,
  getAll,
  switchAdmin,
  deleteAccount,
  enableTwoFactor,
  enableTwoFactorAdmin,
  disableTwoFactor,
  resetTwoFactor,
};

function checkCredentials(email: string, password: string) {
  const request: AuthenticateRequest = { email: email, password: password };
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(request),
  };
  return fetchJSON(`${baseUrl}/check-credentials`, requestOptions).then(
    (response: any) => {
      return response.body;
    }
  );
}

function verifyEmail(token: string) {
  const request: VerifyEmailRequest = { token: token };
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(request),
  };
  return fetchJSON(`${baseUrl}/verify-email`, requestOptions).then(
    (response: any) => {
      return response;
    }
  );
}

function forgotPassword(email: any) {
  const request: ForgotPasswordRequest = { email: email };
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(request),
  };
  return fetchJSON(`${baseUrl}/forgot-password`, requestOptions).then(
    (response: any) => {
      return response;
    }
  );
}

function validateResetToken(token: string) {
  const request: ValidateResetTokenRequest = { token: token };
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(request),
  };
  return fetch(`${baseUrl}/validate-reset-token`, requestOptions).then(
    (response: any) => {
      return response;
    }
  );
}

function resetPassword(
  token: string,
  password: string,
  confirmPassword: string
) {
  const request: ResetPasswordRequest = {
    token: token,
    password: password,
    confirmPassword: confirmPassword,
  };
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(request),
  };
  return fetchJSON(`${baseUrl}/reset-password`, requestOptions).then(
    (response: any) => {
      return response;
    }
  );
}

//This is essentially the "logout" method for this app, which is why there is no logout in api.js
function revokeToken() {
  const requestOptions = {
    method: "POST",
    credentials: "include",
  };
  return fetch(`${baseUrl}/revoke-token`, requestOptions).then(
    (response: any) => {
      localStorage.removeItem("account");
      return response.body;
    }
  );
}

function getAll() {
  return fetch(`${baseUrl}/get-all`).then((response: any) => {
    return response.body;
  });
}

function switchAdmin(emailAddress: string, isAdmin: boolean) {
  const request: SwitchAdminRequest = {
    emailAddress: emailAddress,
    isAdmin: isAdmin,
  };
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(request),
  };
  return fetch(`${baseUrl}/switch-admin`, requestOptions).then(
    (response: any) => {
      return response;
    }
  );
}

function deleteAccount(accountGuid: string) {
  const requestOptions = {
    method: "POST",
  };
  return fetch(`${baseUrl}/delete/${accountGuid}`, requestOptions).then(
    (response: any) => {
      return response;
    }
  );
}

//Two Factor Authentication

function enableTwoFactor(accountGuid: string) {
  const requestOptions = {
    method: "POST",
  };
  return fetch(
    `${baseUrl}/enable-2fa/${accountGuid}?shouldSendEmail=false`,
    requestOptions
  ).then((response: any) => {
    return response.body as TwoFactorSetupCodeResponse;
  });
}

function enableTwoFactorAdmin(accountGuid: string) {
  const requestOptions = {
    method: "POST",
  };
  return fetch(
    `${baseUrl}/enable-2fa-admin/${accountGuid}`,
    requestOptions
  ).then((response: any) => {
    return response;
  });
}

function disableTwoFactor(accountGuid: string) {
  const requestOptions = {
    method: "POST",
  };
  return fetch(`${baseUrl}/disable-2fa/${accountGuid}`, requestOptions).then(
    (response: any) => {
      return response;
    }
  );
}

function resetTwoFactor(accountGuid: string) {
  const requestOptions = {
    method: "POST",
  };
  return fetch(`${baseUrl}/reset-2fa/${accountGuid}`, requestOptions).then(
    (response: any) => {
      return response.body as TwoFactorSetupCodeResponse;
    }
  );
}
