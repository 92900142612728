import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { MenuItem } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { inviteService } from "../../../services/inviteService";
import { FORM_STATUS } from "../../../model/formStatus";
import { InviteRequest } from "../../../model/inviteRequest";
import { useAppThunkDispatch } from "../../../redux/configureStore";
import { getActiveInvites } from "../../../redux/actions/adminActions";

const useStyles = makeStyles((theme: any) => ({
  form: {
    width: "fit-content",
    padding: "10px 20px 10px 20px",
    background: theme.palette.common.white,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  textField: {
    "& .Mui-focused legend": {
      paddingRight: 20,
    },
  },
  passwordField: {
    "& .Mui-focused legend": {
      paddingRight: 10,
    },
  },
}));

const defaultState: InviteRequest = {
  firstName: "",
  lastName: "",
  role: 1,
  email: "",
};

const InviteUserComponent: React.VoidFunctionComponent = () => {
  const classes = useStyles();
  const [formData, setFormData] = useState<InviteRequest>(defaultState);
  const [status, setStatus] = useState(FORM_STATUS.IDLE);
  const [touched, setTouched] = useState(defaultState);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppThunkDispatch();

  function handleChange(e: any) {
    setFormData((curFormData) => {
      return {
        ...curFormData,
        [e.target.name]: e.target.value,
      };
    });
  }

  function handleBlur(e: any) {
    e.persist();
    setTouched((cur: any) => {
      return { ...cur, [e.target.id]: true };
    });
  }

  function getErrors(formData: any) {
    const result = {
      firstName: "",
      lastName: "",
      email: "",
    };
    if (status === FORM_STATUS.COMPLETED) return result;

    if (!formData.firstName) result.firstName = "Please enter a first name.";
    if (!formData.lastName) result.lastName = "Please enter a last name.";

    if (!formData.email) {
      result.email = "Please enter an email address.";
    } else if (!/^.+@.+$/i.test(formData.email)) {
      result.email = "The email address is Invalid.";
    }

    return result;
  }

  //Derived state
  const errors = getErrors(formData);
  const isValid = Object.values(errors).every((x) => x === null || x === "");

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setStatus(FORM_STATUS.SUBMITTING);
    if (isValid) {
      setIsLoading(true);
      try {
        inviteService.inviteUser(formData).then((response: any) => {
          setStatus(FORM_STATUS.COMPLETED);
          setFormData(defaultState);
          setIsLoading(false);
          dispatch(getActiveInvites());
        });
      } catch (e) {
        //This is not the exception you are looking for
        setIsLoading(false);
      }
    } else {
      setStatus(FORM_STATUS.SUBMITTED);
    }
  };

  return (
    <Box className={classes.form}>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h5" gutterBottom component="h2">
          Invite User
        </Typography>
      </Box>
      <Box component="form" noValidate onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              autoComplete="given-name"
              name="firstName"
              required
              fullWidth
              id="firstName"
              label="First Name"
              value={formData.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={status === FORM_STATUS.COMPLETED ? true : false}
              helperText={
                (touched.firstName || status === FORM_STATUS.SUBMITTED) &&
                errors.firstName
              }
              error={
                (touched.firstName !== "" ||
                  status === FORM_STATUS.SUBMITTED) &&
                errors.firstName !== ""
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              autoComplete="family-name"
              value={formData.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={status === FORM_STATUS.COMPLETED ? true : false}
              helperText={
                (touched.lastName || status === FORM_STATUS.SUBMITTED) &&
                errors.lastName
              }
              error={
                (touched.lastName !== "" || status === FORM_STATUS.SUBMITTED) &&
                errors.lastName !== ""
              }
            />
          </Grid>
        </Grid>

        <Grid item sx={{ mt: 2 }} xs={12}>
          <TextField
            select
            id="role"
            label="Role"
            name="role"
            value={formData.role}
            onChange={handleChange}
            disabled={status === FORM_STATUS.COMPLETED ? true : false}
          >
            <MenuItem value={0}>Admin</MenuItem>
            <MenuItem value={1}>User</MenuItem>
          </TextField>
        </Grid>
        <Grid item sx={{ mt: 2 }} xs={12}>
          <TextField
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={formData.email}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={status === FORM_STATUS.COMPLETED ? true : false}
            helperText={
              (touched.email || status === FORM_STATUS.SUBMITTED) &&
              errors.email
            }
            error={
              (touched.email !== "" || status === FORM_STATUS.SUBMITTED) &&
              errors.email !== ""
            }
          />
        </Grid>

        <LoadingButton
          type="submit"
          loading={isLoading}
          variant="contained"
          color="primary"
          className={classes.submit}
          sx={{ mt: 2 }}
          disabled={status === FORM_STATUS.COMPLETED ? true : false}
        >
          Submit
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default InviteUserComponent;
