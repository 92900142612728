export function handleResponse(response) {
  return new Promise(function (resolve, reject) {
    const responseData = response.response;
    const data = response.body && JSON.parse(JSON.stringify(response.body));

    if (!responseData.ok) {
      if ([401, 403].includes(responseData.status)) {
        return reject("Unauthorised");
      }
      const error = (data && data.message) || responseData.statusText;
      return reject(error);
    } else {
      return resolve(data);
    }
  });
}
