import {
  FETCH_PATIENTS,
  FETCH_PATIENTS_SUCCESS,
  FETCH_PATIENTS_FAILURE,
  FETCH_PATIENT_TESTS,
  FETCH_PATIENT_TESTS_SUCCESS,
  FETCH_PATIENT_TESTS_FAILURE,
  FETCH_PATIENT_TEST_DETAIL,
  FETCH_PATIENT_TEST_DETAIL_SUCCESS,
  FETCH_PATIENT_TEST_DETAIL_FAILURE,
  FETCH_PATIENT_TEST_TABLE_DATA,
  FETCH_PATIENT_TEST_TABLE_DATA_SUCCESS,
  FETCH_PATIENT_TEST_TABLE_DATA_FAILURE,
  SET_SELECTED_TEST_DATE,
  CLEAR_SELECTED_PATIENT_TEST_DETAIL,
} from "../constants";
import { PatientActionTypes } from "../types/patientActionTypes";
import { Dispatch } from "redux";
import { activeTestService } from "../../services/activeTestService";
import { TestData } from "../../model/testData";
import { TestDataCategoryType } from "../../model/testDataCategoryType";
import { patientService } from "../../services/patientService";
import { removeSpacesFromObject } from "../../helpers/utilityFunctions/utilityFunctions";

//patients

export function fetchPatients(): PatientActionTypes {
  return {
    type: FETCH_PATIENTS,
  };
}

export function fetchPatientsSuccess(data: any) {
  return {
    type: FETCH_PATIENTS_SUCCESS,
    payload: removeSpacesFromObject(data),
  };
}

export function fetchPatientsError(error: string): PatientActionTypes {
  return {
    type: FETCH_PATIENTS_FAILURE,
    payload: error,
  };
}

export function getPatients(accoundID: string, isAuthorized: boolean) {
  return function action(dispatch: Dispatch) {
    dispatch(fetchPatients());
    return patientService.getPatients(accoundID, isAuthorized).then(
      (response: any) => dispatch(fetchPatientsSuccess(response))
      //error => dispatch(someerror('woody', buzz, error))
    );
  };
}

//patientTests

export function fetchPatientTests(): PatientActionTypes {
  return {
    type: FETCH_PATIENT_TESTS,
  };
}

export function fetchPatientTestsSuccess(data: any) {
  return {
    type: FETCH_PATIENT_TESTS_SUCCESS,
    payload: data,
  };
}

export function fetchPatientTestsError(error: string): PatientActionTypes {
  return {
    type: FETCH_PATIENT_TESTS_FAILURE,
    payload: error,
  };
}

export function getPatientTests(patientId: string, testType: string) {
  return function action(dispatch: Dispatch) {
    dispatch(fetchPatientTests());
    return activeTestService.GetPatientTests(patientId, testType).then(
      (response: any) => dispatch(fetchPatientTestsSuccess(response))
      //error => dispatch(someerror('woody', buzz, error))
    );
  };
}

//patientTestDetail
export function fetchPatientTestDetail(): PatientActionTypes {
  return {
    type: FETCH_PATIENT_TEST_DETAIL,
  };
}

export function fetchPatientTestDetailSuccess(data: TestData) {
  return {
    type: FETCH_PATIENT_TEST_DETAIL_SUCCESS,
    payload: data,
  };
}

export function fetchPatientTestDetailError(error: string): PatientActionTypes {
  return {
    type: FETCH_PATIENT_TEST_DETAIL_FAILURE,
    payload: error,
  };
}

export function getPatientTestDetail(testGuid: string, testType: string) {
  return function action(dispatch: Dispatch) {
    dispatch(fetchPatientTestDetail());
    return activeTestService.GetPatientTestDetail(testGuid, testType).then(
      (response: any) => {
        dispatch(fetchPatientTestDetailSuccess(response));
      }
      //error => dispatch(someerror('woody', buzz, error))
    );
  };
}

//patientTestTableData
export function fetchPatientTestTableDataByCategory(): PatientActionTypes {
  return {
    type: FETCH_PATIENT_TEST_TABLE_DATA,
  };
}

export function fetchPatientTestTableDataByCategorySuccess(
  key: TestDataCategoryType,
  data: TestData
) {
  return {
    type: FETCH_PATIENT_TEST_TABLE_DATA_SUCCESS,
    payload: {
      key: key,
      data: data,
    },
  };
}

export function fetchPatientTestTableDataByCategoryError(
  error: string
): PatientActionTypes {
  return {
    type: FETCH_PATIENT_TEST_TABLE_DATA_FAILURE,
    payload: error,
  };
}

export function setSelectedTestDate(dateTime: string): PatientActionTypes {
  return {
    type: SET_SELECTED_TEST_DATE,
    payload: dateTime,
  };
}

export function getPatientTestTableDataByCategory(
  testId: string,
  dateTime: string,
  activeType: string
) {
  return function action(dispatch: Dispatch) {
    dispatch(setSelectedTestDate(dateTime));
    Object.keys(TestDataCategoryType)
      .filter((key: any) => !isNaN(Number(TestDataCategoryType[key])))
      .map((key: any) => {
        dispatch(fetchPatientTestTableDataByCategory());
        return activeTestService
          .GetPatientTestTableDataByCategory(testId, activeType, key)
          .then(
            (response: any) => {
              const typeEnum = key as TestDataCategoryType;
              dispatch(
                fetchPatientTestTableDataByCategorySuccess(typeEnum, response)
              );
            }
            //error => dispatch(someerror('woody', buzz, error))
          );
      });
  };
}

export function clearSelectedTestData(): PatientActionTypes {
  return {
    type: CLEAR_SELECTED_PATIENT_TEST_DETAIL,
  };
}
