import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppThunkDispatch } from "../../../redux/configureStore";
import { getPatients } from "../../../redux/actions/patientActions";
import { makeStyles } from "@mui/styles";
import PatientsHeader from "./PatientsHeader";
import PatientTable from "./PatientTable";
import { Box } from "@mui/material";
import { selectAccount } from "../../../redux/selectors/accountSelectors";

const useStyles = makeStyles((theme: any) => ({
  content: {
    padding: theme.spacing.unit * 3,
    width: "100%",
    height: "100%",
    overflow: "auto",
  },
}));

const PatientsPage: React.VoidFunctionComponent = () => {
  const classes = useStyles();
  const account = useSelector(selectAccount);
  const dispatch = useAppThunkDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (account.accountGuid) {
      setIsLoading(true);
      dispatch(getPatients(account.accountGuid, true)).then((response: any) => {
        setIsLoading(false);
      });
    }
  }, [account.accountGuid, dispatch]);

  return (
    <main className={classes.content}>
      <PatientsHeader setIsLoading={setIsLoading} />
      <Box sx={{ mr: 5 }}>
        <PatientTable isLoading={isLoading} />
      </Box>
    </main>
  );
};

export default PatientsPage;
