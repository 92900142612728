import React from "react";
import { useSelector } from "react-redux";
import { store, useAppThunkDispatch } from "../../../redux/configureStore";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import {
  userDailyMetricsVisibilitySettings,
  userDailyParametersVisibilitySettings,
  userPatientTestVisibilitySettings,
} from "../../../redux/selectors/settingsSelectors";
import {
  saveUserSettings,
  updateUserSetting,
} from "../../../redux/actions/settingsActions";
import { SettingsState } from "../../../model/admin/settingsState";
import { selectAccountId } from "../../../redux/selectors/accountSelectors";

const formatLabel = (key: string) => {
  return key
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase());
};

const SettingsPage: React.VoidFunctionComponent = () => {
  const dispatch = useAppThunkDispatch();

  const accountId = useSelector(selectAccountId);

  const userDailyMetricsVisibility = useSelector(
    userDailyMetricsVisibilitySettings
  );
  const userDailyParametersVisibility = useSelector(
    userDailyParametersVisibilitySettings
  );
  const userPatientTestVisibility = useSelector(
    userPatientTestVisibilitySettings
  );

  console.log(
    "state on settings page",
    userDailyMetricsVisibility,
    userDailyParametersVisibility,
    userPatientTestVisibility
  );

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    category: keyof SettingsState,
    key: string
  ) => {
    dispatch(updateUserSetting(category, key, event.target.checked))
      .then(() => {
        //I dont like this
        const state = store.getState();
        let value;

        switch (category) {
          case "DailyMetricsVisibility":
            value = state.settings.user.DailyMetricsVisibility;
            break;
          case "DailyParametersVisibility":
            value = state.settings.user.DailyParametersVisibility;
            break;
          case "PatientTestVisibility":
            value = state.settings.user.PatientTestVisibility;
            break;
          default:
            throw new Error(`Unknown category: ${category}`);
        }

        dispatch(saveUserSettings(accountId, category, value));
      })
      .catch((error) => {
        console.error("Error updating user setting:", error);
      });
  };

  const renderCheckboxes = (visibility: any, category: keyof SettingsState) => (
    <Grid container spacing={2} alignItems="center" wrap="wrap">
      {Object.keys(visibility).map((key) => (
        <Grid item key={key}>
          <FormControlLabel
            control={
              <Checkbox
                checked={visibility[key]}
                onChange={(event) => handleChange(event, category, key)}
                name={key}
              />
            }
            label={formatLabel(key)}
          />
        </Grid>
      ))}
    </Grid>
  );

  console.log("on settings page", userDailyMetricsVisibility);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", marginRight: 30 }}>
      <Typography variant="h4" component="h4">
        Settings
      </Typography>
      <Typography sx={{ mt: 2 }}>
        On this page, you can adjust the settings for your user account. Please
        note that some settings may be overridden by practice-wide settings,
        which can change between logins.
      </Typography>
      <Typography sx={{ mt: 2 }} variant="h5" component="h5">
        Card Visibility
      </Typography>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <Typography variant="h6">Daily Metrics</Typography>
          {renderCheckboxes(
            userDailyMetricsVisibility,
            "DailyMetricsVisibility" as keyof SettingsState
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Daily Parameters</Typography>
          {renderCheckboxes(
            userDailyParametersVisibility,
            "DailyParametersVisibility" as keyof SettingsState
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Patient Tests</Typography>
          {renderCheckboxes(
            userPatientTestVisibility,
            "PatientTestVisibility" as keyof SettingsState
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default SettingsPage;
