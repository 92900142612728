import { fetch } from "../helpers/api/api";
import { handleResponse } from "../helpers/handleResponse";
import { saveJsonAsFile } from "../helpers/utilityFunctions/utilityFunctions";

const baseUrl = `${process.env.REACT_APP_API_URL}/activetest`;

export const activeTestService = {
  GetPatientTests,
  GetPatientTestDetail,
  GetPatientTestTableDataByCategory,
  GetActiveTestOverview,
  GetActiveTestDatesByRange,
  GetActiveTestDataByDate,
  DownloadActiveTestDataFile,
};

function GetPatientTests(patientId, type) {
  return fetch(
    `${baseUrl}/tests?patientId=${patientId}&activeType=${type}`
  ).then((response) => handleResponse(response));
}

function GetPatientTestDetail(testGuid, type) {
  return fetch(
    `${baseUrl}/tests/data/?testGuid=${testGuid}&activeType=${type}`
  ).then((response) => handleResponse(response));
}

function GetPatientTestTableDataByCategory(testGuid, type, category) {
  return fetch(
    `${baseUrl}/tests/data/type/?testGuid=${testGuid}&activeType=${type}&testDataCategoryType=${category}`
  ).then((response) => handleResponse(response));
}

function GetActiveTestOverview(patientGuid) {
  return fetch(`${baseUrl}/tests/overview/${patientGuid}`).then((response) =>
    handleResponse(response)
  );
}

function GetActiveTestDatesByRange(
  patientGuid,
  activeType,
  startDate,
  endDate
) {
  return fetch(
    `${baseUrl}/dates/range?accountGuid=${patientGuid}&activeType=${activeType}&dateStart=${startDate}&dateEnd=${endDate}`
  ).then((response) => handleResponse(response));
}

function GetActiveTestDataByDate(patientGuid, activeType, date) {
  return fetch(
    `${baseUrl}/tests?patientId=${patientGuid}&activeType=${activeType}&date=${date}`
  ).then((response) => handleResponse(response));
}

function DownloadActiveTestDataFile(testGuid, activeType, filename) {
  return fetch(
    `${baseUrl}/tests/data?testGuid=${testGuid}&activeType=${activeType}`
  ).then((response) => {
    saveJsonAsFile(filename, response.body);
  });
}
