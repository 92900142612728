import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { Box } from "@mui/material";
import TestDetailHeader from "../TestDetailHeader";
import { ActiveType } from "../../../../model/activeType";

import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import TableRow from "@mui/material/TableRow";
import { useSelector } from "react-redux";
import {
  selectedTestTableData,
  selectedTestTableDataIsLoading,
} from "../../../../redux/selectors/patientSelectors";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";

import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import IconLoader from "../../../../components/IconLoader";
import Scrollable from "../../../../components/Scrollable";
import PathCircularProgress from "../../../../components/PathCircularProgress";
import { zip } from "../../../../helpers/utilityFunctions/utilityFunctions";
import { TestDataCategoryType } from "../../../../model/testDataCategoryType";
import { useAppThunkDispatch } from "../../../../redux/configureStore";
import { clearSelectedTestData } from "../../../../redux/actions/patientActions";

const useStyles = makeStyles((theme: any) => ({
  tableCellHeader: {
    "&.MuiTableCell-root": {
      height: "48px",
      width: "60px",
      padding: "0px 10px 0px 10px",
      textAlign: "center",
      fontWeight: 700,
    },
  },
  tableCell: {
    "&.MuiTableCell-root": {
      padding: "0px 10px 0px 10px",
      width: "60px",
      height: "24px",
      textAlign: "center",
    },
  },
  emptyCell: {
    "&.MuiTableCell-root": {
      padding: "0px",
      width: "60px",
      height: "40px",
    },
  },
  headerText: {
    color: "white",
    transform: "rotate(-90deg)",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const TenMetreWalkDetail: React.FC = () => {
  const { patientId, testType } = useParams();
  const classes = useStyles();
  const dispatch = useAppThunkDispatch();
  const tableData = useSelector(selectedTestTableData);
  const isLoading = useSelector(selectedTestTableDataIsLoading);
  const typeString = testType as keyof typeof ActiveType;
  const type: ActiveType = ActiveType[typeString];

  //clear test date when navifgation away

  useEffect(() => {
    return () => {
      dispatch(clearSelectedTestData());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <TestDetailHeader patientId={patientId} testType={type} />
      <Scrollable>
        {Object.keys(TestDataCategoryType)
          .filter((key: any) => !isNaN(Number(TestDataCategoryType[key])))
          .map((parentKey: any) => {
            return (
              <Box
                sx={{ display: "flex", flexDirection: "row", margin: "20px" }}
                key={parentKey}
              >
                <Box
                  sx={{
                    width: 30,
                    borderRadius: "10px 0px 0px 10px",
                    backgroundColor: "#28666C",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    className={classes.headerText}
                    component="h1"
                    variant="h5"
                  >
                    {parentKey}
                  </Typography>
                </Box>
                <TableContainer component={Paper} sx={{ width: "130px" }}>
                  <Table aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell className={classes.emptyCell} />
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableCellHeader}>
                          metric
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableCell}>
                          unit
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableCell}>
                          foot
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableCell}>
                          mean
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableCell}>
                          median
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableCell}>sd</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableCell}>iqr</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableCell}>max</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableCell}>min</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableCell}>
                          ratio
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.tableCell}>cv</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                {isLoading ? (
                  <Paper
                    sx={{
                      width: "200px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <PathCircularProgress />
                  </Paper>
                ) : (
                  <>
                    <Paper
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                      }}
                    >
                      {tableData &&
                        Object.entries(tableData)
                          .filter((key: any) => key.includes(parentKey))
                          ?.map((result: any) => {
                            return Object.entries(result[1]).map(
                              ([key, value]: any) => {
                                return (
                                  <TableContainer key={key}>
                                    <Table aria-label="simple table">
                                      <TableBody>
                                        <TableRow>
                                          <TableCell
                                            className={classes.tableCell}
                                            colSpan={2}
                                          >
                                            <IconLoader name={value.metric} />
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell
                                            className={classes.tableCellHeader}
                                            colSpan={2}
                                          >
                                            {value.metric}
                                          </TableCell>
                                        </TableRow>

                                        <TableRow>
                                          <TableCell
                                            className={classes.tableCell}
                                            colSpan={2}
                                          >
                                            {value.unit}
                                          </TableCell>
                                        </TableRow>

                                        <TableRow>
                                          <TableCell
                                            className={classes.tableCell}
                                          >
                                            L
                                          </TableCell>
                                          <TableCell
                                            className={classes.tableCell}
                                          >
                                            R
                                          </TableCell>
                                        </TableRow>
                                        {(() => {
                                          const result = zip(
                                            Object.values(value.foot.left),
                                            Object.values(value.foot.right)
                                          );
                                          return (
                                            result &&
                                            result.map((entry, index) => (
                                              <StyledTableRow key={index}>
                                                <TableCell
                                                  className={classes.tableCell}
                                                >
                                                  {entry[0]}
                                                </TableCell>
                                                <TableCell
                                                  className={classes.tableCell}
                                                >
                                                  {entry[1]}
                                                </TableCell>
                                              </StyledTableRow>
                                            ))
                                          );
                                        })()}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                );
                              }
                            );
                          })}
                    </Paper>
                  </>
                )}
              </Box>
            );
          })}
      </Scrollable>
    </Box>
  );
};

export default TenMetreWalkDetail;
