import * as React from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box, Button, Typography } from "@mui/material";
import { ThreeDotsMenu } from "../../../components/documented/ThreeDotsMenu";
import { useAppThunkDispatch } from "../../../redux/configureStore";
import { selectInvites } from "../../../redux/selectors/adminSelectors";
import { useEffect, useState } from "react";
import { getActiveInvites } from "../../../redux/actions/adminActions";
import { makeStyles } from "@mui/styles";
import { inviteService } from "../../../services/inviteService";
import PathCircularProgress from "../../../components/PathCircularProgress";
import NoData from "../../../components/NoData";

const useStyles = makeStyles((theme: any) => ({
  container: {
    height: "fit-content",
    width: "fit-content",
    padding: "10px 20px 10px 20px",
    background: theme.palette.common.white,
  },
  header: {
    height: "auto",
    display: "flex",
    justifyContent: "space-between",
  },
  form: {
    maxHeight: "500px",
    overflowX: "hidden",
    overflowY: "auto",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: "pointer",
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const ManageInvitesComponent: React.VoidFunctionComponent = () => {
  const classes = useStyles();
  const invites = useSelector(selectInvites);
  const dispatch = useAppThunkDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getActiveInvites()).then((response: any) => {
      setIsLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleResendClick(email: string) {
    try {
      setIsLoading(true);
      inviteService.resendInviteRequest(email).then((response: any) => {
        setIsLoading(false);
      });
    } catch (error: any) {
      //hmmm
    }
  }

  function handleDeleteInvite(inviteCodeGuid: string) {
    try {
      setIsLoading(true);
      inviteService.deleteInvite(inviteCodeGuid).then((response: any) => {
        dispatch(getActiveInvites()).then((response: any) => {
          setIsLoading(false);
        });
      });
    } catch (error: any) {
      //hmmm
    }
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <Typography
          sx={{ padding: "5px 0px 5px 0px" }}
          variant="h5"
          gutterBottom
          component="h2"
        >
          Manage Invites
        </Typography>
        {isLoading ? <PathCircularProgress /> : null}
      </Box>
      <Box className={classes.form}>
        {!isLoading && invites.length ? (
          <TableContainer>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left">First Name</StyledTableCell>
                  <StyledTableCell align="left">Last Name</StyledTableCell>
                  <StyledTableCell align="left">Email</StyledTableCell>
                  <StyledTableCell align="left">Invited By</StyledTableCell>
                  <StyledTableCell align="left">Date</StyledTableCell>
                  <StyledTableCell align="left">Invite Email</StyledTableCell>
                  <StyledTableCell align="left">Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invites &&
                  invites.map((invite, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="left">
                        {invite.firstName}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {invite.lastName}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {invite.email}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {invite.invitedBy}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {new Date(invite.created).toDateString()}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Button onClick={() => handleResendClick(invite.email)}>
                          Resend
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell>
                        <ThreeDotsMenu
                          data={invite.invitationCodeGuid}
                          handleAction={handleDeleteInvite}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <NoData message="No Invites for you to manage right now" />
        )}
      </Box>
    </Box>
  );
};

export default ManageInvitesComponent;
